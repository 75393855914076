import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';

import BinIcon from '../../../../components/CustomIcons/BinIcon';
import CrossIcon from '../../../../components/CustomIcons/CrossIcon';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import CustomButton from '../../../../components/CustomButton';
import Department from '../../../../types/department';
import Dimens from '../../../../theme/dimens';
import React from 'react';
import { removeDeptUserSchema } from '../../../../utils/validation-schema';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props extends DialogProps {
	isDefault?: boolean;
	deptList: Array<Department>;
	onPrimaryAction: (department?: Department) => void;
	onClose: () => void;
}

/**
 * Default Department User Removal Dialog component.
 *
 * This component renders a dialog for removing a user from a department.
 * It supports two scenarios:
 *   - Removing a user from their default department (isDefault = true).
 *   - Removing a user from a specific department (isDefault = false).
 *
 * @param {Props} props - Component props.
 * @returns {JSX.Element} JSX element representing the dialog component.
 */
const DefaultDeptUserRemoveDialog: React.FC<Props> = (props: Props) => {

	const {
		isDefault,
		deptList,
		onPrimaryAction,
		onClose,
		...rest
	} = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const initialValue = {
		department: undefined
	};

	return (
		<>
			{rest.open &&
				<Dialog {...rest} sx={styles.dialog}>
					<DialogTitle>
						<Stack sx={styles.header}>
							<Box sx={styles.iconWrapper}>
								{<BinIcon />}
							</Box>
							<Box flex={1} />
							<IconButton onClick={onClose} >
								<CrossIcon sx={styles.closeIcon} />
							</IconButton>
						</Stack>
					</DialogTitle>
					<DialogContent>
						<Typography variant={'h5'} fontWeight={Dimens.fontWeight.bold}>
							{t('removeUserFromDept')}
						</Typography>
						<Typography variant='p1' sx={styles.message}>
							{isDefault ? t('removeUserFromDeptMsg') : t('removeDeptUserMsg')}
						</Typography>
					</DialogContent>
					<DialogActions>
						<Formik
							enableReinitialize
							validationSchema={isDefault ? removeDeptUserSchema : undefined}
							initialValues={initialValue}
							onSubmit={values => onPrimaryAction(values.department)}>
							{({ dirty, isValid }) => (
								<Form style={styles.form as React.CSSProperties}>
									{isDefault &&
										<Field
											name='department'
											label={t('department')}
											placeholder={t('findDepartments')}
											component={CustomAutocomplete}
											noOptionsText={t('noDepts')}
											menu={deptList}
										/>
									}
									<Stack sx={styles.btnLayout}>
										<CustomButton
											title={t('cancel')}
											color='secondary'
											onClick={() => onClose()}
										/>
										<CustomButton
											type='submit'
											title={t('delete')}
											color='primary'
											destructive
											disabled={isDefault && (!dirty || !isValid)}
										/>
									</Stack>
								</Form>
							)}
						</Formik>
					</DialogActions>
				</Dialog>
			}
		</>
	);
};

export default DefaultDeptUserRemoveDialog;
