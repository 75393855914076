import { Box, Dialog, DialogProps, Table, TableBody, TableContainer } from '@mui/material';
import { CALL_LOG_STATUS_LIST, getMessagePriorityList } from '../../../utils/ui-constants';
import { DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_SS, MessagePriority, PagerStatus } from '../../../utils/constants';

import { CallLogData } from '../../../types/call-log-data';
import CallLogDetailTableHeader from './CallLogDetailTableHeader';
import CallLogDetailTableRow from './CallLogDetailTableRow';
import CallLogMessageInfoItem from './CallLogMessageInfoItem';
import CustomButton from '../../../components/CustomButton';
import React, { } from 'react';
import TopBar from '../../../components/TopBar';
import Util from '../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props extends DialogProps {
  callLogData: CallLogData;
  onClose: () => void;
}

/**
 * React functional component for the Call Log Detail Dialog.
 *
 * This component displays detailed information about a specific call log entry.
 * It retrieves data passed through location state and renders sections for:
 *  - Call Log Summary (sent from, priority, status, message)
 *  - Recipients table with individual message details (expandable)
 *
 * @returns {JSX.Element} JSX element representing the Call Log Detail screen.
 */
const CallLogDetailDialog: React.FC<Props> = (props: Props) => {

  const { callLogData, onClose, ...rest } = props;
  const styles = useStyles();
  const { t } = useTranslation();

  /**
   * Gets the priority value based on its ID.
   *
   * @param {MessagePriority} priority - The priority ID.
   * @returns {string} The priority value or "-" if not found.
   */
  const getPriority = (priority: MessagePriority) => {

    return (getMessagePriorityList(true).find(item => item.id === priority)?.value ?? '-') as string;
  }

  /**
   * Gets the status value based on its ID.
   *
   * @param {PagerStatus} status - The status ID.
   * @returns {string} The status value or "-" if not found.
   */
  const getStatus = (status: PagerStatus) => {

    return (CALL_LOG_STATUS_LIST.find(item => item.id === status)?.value ?? '-') as string;
  }

  return (
    <Dialog {...rest} sx={styles.dialog} onClose={onClose} hideBackdrop>
      <Box sx={styles.container}>
        <TopBar
          title={t('messageInfo')}
          subTitle={Util.formatUTCtoLocal(callLogData.summary.createdAt, DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_SS)}
        />
        <Box sx={styles.basicInfoWrapper}>
          <Box sx={styles.basicInfoLayout}>
            <CallLogMessageInfoItem label={t('sentFrom')} value={callLogData.summary.senderName} />
            <Box sx={styles.basicInfoInnerLayout}>
              <CallLogMessageInfoItem label={t('priority')} value={getPriority(callLogData.summary.priority)} />
              <CallLogMessageInfoItem label={t('status')} value={getStatus(callLogData.summary.flagToDisplay)} />
            </Box>
          </Box>
          <CallLogMessageInfoItem label={t('message')} value={callLogData.summary.contentText} />
        </Box>
        <Box sx={styles.content}>
          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader>
              <CallLogDetailTableHeader />
              <TableBody component={'tbody'}>
                {callLogData.summary.recipients.map((recipient, index) => (
                  <CallLogDetailTableRow key={`call-log-${index}`} recipient={recipient} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={styles.btnWrapper}>
          <CustomButton title={t('close')} onClick={onClose} fullWidth />
        </Box>
      </Box>
    </Dialog>
  );
};

export default CallLogDetailDialog;
