import { Box, Table, TableBody, TableContainer, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { CallLogData } from '../../types/call-log-data';
import CallLogDetailDialog from './CallLogDetailDialog';
import CallLogFilter from '../../types/ui/call-log-filter';
import CallLogFilterRequest from '../../types/call-log-filter-request';
import CallLogListTableHeader from './CallLogListTableHeader';
import CallLogListTableRow from './CallLogListTableRow';
import CallLogSearchAndFilterHeader from './CallLogSearchAndFilterHeader';
import CircularProgressBar from '../../components/CircularProgressBar';
import { FETCH_CALL_LOG_LIST } from '../../data/log/action-types';
import InlineAlert from '../../components/InlineAlert';
import LoginUtil from '../../utils/login-util';
import MenuData from '../../types/ui/menu-data';
import NoDataFound from '../../components/NoDataFound';
import Util from '../../utils/util';
import { getCallLogTableHeaders } from '../../utils/ui-constants';
import { useLogApi } from '../../data/log/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Renders the Call Log screen component.
 *
 * This component displays a search bar, filter options, a table to display call logs,
 * and a loading indicator.
 *
 * @returns {JSX.Element} JSX element representing the Call Log screen.
 */
const CallLogScreen: React.FC = () => {

  const styles = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState<string>('');
  const [filter, setFilter] = useState<CallLogFilter>({
    startDate: new Date(),
    endDate: new Date(),
    senderInfo: {
      id: LoginUtil.getLoginId(),
      name: LoginUtil.getUserFullName()
    }
  });
  const [columns, setColumns] = useState<Array<MenuData>>([]);
  const [selectedCallLog, setSelectedCallLog] = useState<CallLogData>();
  const logApi = useLogApi();
  const apiStatus = logApi.state.apiStatus;

  /**
   * Initial effect to apply the filter when the component mounts.
   */
  useEffect(() => {
    onApplyFilter(filter);
  }, []);

  /**
   * Handles browser back button and history state.
   *
   * 1. Adds a history entry on mount if a call log is selected to prevent accidental navigation away.
   * 2. Listens for the popstate event and prevents default behavior if a call log is selected.
   * 3. Cleans up the event listener on unmount.
   */
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (selectedCallLog) {
        event.preventDefault();
        setSelectedCallLog(undefined);
      }
    }
    if (selectedCallLog) {
      window.history.pushState({name: 'browserBack'}, 'close dialog', window.location.href);
    }
    
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [selectedCallLog]);

  /**
   * Fetches call log table headers based on screen size on initial render and when screen size changes.
   */
  useEffect(() => {
    setColumns(getCallLogTableHeaders(isDesktop));
  }, [isDesktop])

  /**
   * Handles changes in column visibility based on user interaction.
   *
   * @param {MenuData} menu - The menu data object representing the clicked column.
   */
  const onColumnVisibilityChange = (menu: MenuData) => {
    const updatedColumns = [...columns];
    updatedColumns.map(column => {
      if (column.id === menu.id) {
        column.isChecked = menu.isChecked;
      }
    });
    setColumns(updatedColumns);
  }

  /**
   * Checks if the API is currently loading or has encountered an error.
   *
   * @returns {boolean} True if the API is loading or an error has occurred, false otherwise.
   */
  const isApiLoading = (): boolean => {

    return Boolean(apiStatus?.isLoading || apiStatus?.error)
  }

  /**
   * Handles closing the Call Log Detail dialog.
   * Clears the selected call log and navigates back in browser history.
   */
  const onCallLogDetailDialogClose = () => {
    setSelectedCallLog(undefined);
    window.history.back();
  }

  /**
   * Handles applying the filter based on the selected options in the filter form.
   *
   * @param {CallLogFilter} values - The updated filter values from the form.
   */
  const onApplyFilter = (values: CallLogFilter) => {
    values.content = searchKey;
    setFilter(values);
    let startDate = '';
    if (values.startDate) {
      const convertedDate = Util.convertToUTC(values.startDate);
      convertedDate.setMilliseconds(0);
      convertedDate.setSeconds(0);
      convertedDate.setMinutes(0);
      convertedDate.setHours(0);
      startDate = convertedDate.toISOString();
    }
    let endDate = '';
    if (values.endDate) {
      const convertedDate = Util.convertToUTC(values.endDate);
      convertedDate.setMilliseconds(999);
      convertedDate.setSeconds(59);
      convertedDate.setMinutes(59);
      convertedDate.setHours(23);
      endDate = convertedDate.toISOString();
    }
    const request: CallLogFilterRequest = {
      content: searchKey,
      status: values.status,
      startDate: startDate,
      endDate: endDate,
      senderLoginId: values.senderInfo?.id ?? '',
      recipientLoginId: values.recipientInfo?.id
    };
    logApi.fetchCallLogList(request);
  }

  return (
    <Box sx={styles.container}>
      <CallLogSearchAndFilterHeader
        filter={filter}
        onSearchKeyChange={setSearchKey}
        onApplyFilter={onApplyFilter}
        totalResults={logApi.state.callLogList.length}
        isAPILoading={isApiLoading()}
      />
      <Box sx={styles.content}>
        <InlineAlert message={Util.getApiError([FETCH_CALL_LOG_LIST], apiStatus)} />
        {!isApiLoading() &&
          <>
            {Util.isArrayEmpty(logApi.state.callLogList) ? (
              <NoDataFound message={t('noResultsFound')} />
            ) : (
              <TableContainer sx={styles.tableContainer}>
                <Table stickyHeader>
                  <CallLogListTableHeader
                    columns={columns}
                    onColumnVisibilityChange={onColumnVisibilityChange}
                  />
                  <TableBody component={'tbody'}>
                    {logApi.state.callLogList.map((callLogData, index) => (
                      <CallLogListTableRow
                        key={`call-log-${index}`}
                        callLogData={callLogData}
                        columns={columns}
                        onClick={() => setSelectedCallLog(callLogData)}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        }
      </Box>
      <CircularProgressBar show={Util.isApiLoading([FETCH_CALL_LOG_LIST], apiStatus)} />
      {selectedCallLog &&
        <CallLogDetailDialog
          open={Boolean(selectedCallLog)}
          callLogData={selectedCallLog}
          onClose={onCallLogDetailDialogClose}
        />
      }
    </Box>
  );
};

export default CallLogScreen;
