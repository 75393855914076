import {
	API_FAILURE,
	CREATE_DEPT,
	DELETE_DEPT,
	DEPT_LIST_SUCCESS,
	DeptActionTypes,
	FETCH_DEPT_DETAIL,
	FETCH_DEPT_LIST,
	REMOVE_DEPT_USER,
	RESET_DEPT_STATE,
	UPDATE_DEPT,
} from './action-types';

import DepartmentState from '../../types/states/dept-state';

export const deptInitialState: DepartmentState = {
	apiStatus: undefined,
	departmentList: []
};

/**
 * Department reducer
 *
 * Handles state updates for department-related data, including fetching, success, and failure states.
 */
export const deptReducer = (state: DepartmentState, action: DeptActionTypes): DepartmentState => {
	switch (action.type) {
		case FETCH_DEPT_LIST:
		case CREATE_DEPT:
		case UPDATE_DEPT:
		case FETCH_DEPT_DETAIL:
		case DELETE_DEPT:
		case REMOVE_DEPT_USER:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case DEPT_LIST_SUCCESS:
			return {
				...state,
				departmentList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true,
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: undefined,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_DEPT_STATE:
			return {
				...deptInitialState
			};
		default:
			return state;
	}
};

export default deptReducer;
