import Dimens from '../../../theme/dimens';
import { PagerStatus } from '../../../utils/constants';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Hook to generate styles for the Call Log table row.
 *
 * @param {PagerStatus} status - The status of the pager.
 * @returns {Object} An object containing the styles for the table row.
 */
const useStyles = (status = PagerStatus.Progress) => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { xs, lg } = Dimens.spacing;

  const styles = {
    tableRow: {
      cursor: 'pointer'
    },
    optionMenuBtn: {
      justifyContent: 'end',
      '& svg':{
        ...commonStyles.smIcon
      }
    },
    multitLineCell: {
      '& p': {
        ...commonStyles.textEllipsis(1),
        whiteSpace: 'nowrap',
        py: lg,
        '&:first-of-type': {
          pt: 0
        },
        '&:last-of-type': {
          pb: 0
        }
      }
    },
    dateTimeCell: {
      position: 'relative'
    },
    dateTimeWrapper: {
      position: 'relative',
      pl: lg
    },
    statusMark: {
      position: 'absolute',
      width: xs,
      bgcolor: () => {
        let color = theme.palette.warning.main;
        if (status === PagerStatus.Error) {
          color = theme.palette.error.main;
        } else if (status === PagerStatus.Closed) {
          color = theme.palette.success.main;
        }

        return color;
      },
      top: 0,
      bottom: 0,
      left: 0,
      my: xs,
      ml: xs
    },
    voiceMsgWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& svg': {
        ...commonStyles.smIcon,
        mr: xs
      }
    }
  };

  return styles;
};

export default useStyles;
