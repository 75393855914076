import { useMediaQuery, useTheme } from '@mui/material';

import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import { Props } from '.';
import ThemeUtil from '../../theme/theme-util';

/**
 * This custom hook generates styles for the AlertDialogSmall component based on Material UI and theme dimensions.
 * It uses the `useTheme` hook to access the current theme and `useMediaQuery` to handle responsive styles.
 * 
 * @param {Props} props - Props passed to the AlertDialogSmall component, used to conditionally style elements.
 * @returns {object} - An object containing CSS styles for various elements of the dialog.
 */
const useStyles = (props: Props) => {

  const { xs, sm, base, lg, xl } = Dimens.spacing;
  const theme = useTheme();
  const isLightTheme = ThemeUtil.isLightTheme(theme);

  const styles = {
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        maxWidth: Dimens.alertDialogSmallWidth.lg,
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('sm')]: {
          maxWidth: Dimens.alertDialogSmallWidth.sm
        }
      },
      '& .MuiDialogTitle-root': {
        p: 0
      },
      '& .MuiDialogContent-root': {
        p: 0
      },
      '& .MuiDialogActions-root': {
        p: 0,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: useMediaQuery(theme.breakpoints.down('sm')) ? 'column-reverse' : 'row',
        '& .MuiFormControlLabel-root': {
          display: props.showCheckboxFooter ? 'flex' : 'none',
          m: 0
        },
        '& .MuiCheckbox-root': {
          p: 0,
          mr: sm
        },
        [theme.breakpoints.down('sm')]: {
          alignItems: 'start',
          '& .MuiFormControlLabel-root': {
            m: `${sm} 0 0 0`
          }
        }
      }
    },
    header: {
      flexDirection: 'row',
      flex: 1,
      alignItems: 'start',
      justifyContent: 'space-between',
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    iconWrapper: {
      bgcolor: () => {
        let color;
        if (props.isDestructive) {
          color = isLightTheme ? theme.palette.error.light : theme.palette.error.dark;
        } else {
          color = isLightTheme ? theme.palette.primary.light : theme.palette.primary.dark
        }

        return color;
      },
      borderRadius: Dimens.radius.xxl,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      padding: Dimens.highlightIconPadding,
      border: () => {
        let color;
        if (props.isDestructive) {
          color = isLightTheme ? Colors.palette.error[50] : theme.palette.error.light;
        } else {
          color = isLightTheme ? Colors.palette.primary[50] : Colors.palette.primary[700];
        }

        return `${xs} solid ${color}`;
      },
      'svg': {
        color: () => {
          let color;
          if (isLightTheme) {
            color = props.isDestructive ? theme.palette.error.main : theme.palette.primary.main;
          } else {
            color = theme.palette.background.paper;
          }

          return color;
        }
      }
    },
    message: {
      p: props.titleIcon ? `${xs} 0 ${lg} 0` : `0 0 ${lg} 0`,
    },
    footerCheckbox: {
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap'
    },
    btnLayout: {
      flexDirection: useMediaQuery(theme.breakpoints.down('sm')) ? 'column-reverse' : 'row',
      flex: props.showCheckboxFooter ? 'unset' : 1,
      ml: '0 !important',
      'button': {
        flex: props.showCheckboxFooter ? 'unset' : 1
      },
      '& .MuiButton-colorPrimary': {
        display: props.primaryLabel ? 'flex' : 'none',
      },
      '& .MuiButton-colorSecondary': {
        marginRight: xs,
        display: props.secondaryLabel ? 'flex' : 'none',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        '& .MuiButton-colorSecondary': {
          mt: xs,
          mr: 0
        }
      }
    }
  };

  return styles;
};

export default useStyles;
